import Icon from "@/components/global/icon/Icon";
import { CardWithTitle } from "../case_details/components/cards/DetailsCards";
import OtherVitalDetails from "./components/otherVitals/otherVitals";
import PatientMonitoring from "./components/patientMonitoring/patientMonitoring";
import { Card } from "@/components/ui/card";
import { memo, useEffect, useState } from "react";
import useEvents from "@/api/hooks/useEvents";
import useOrders from "@/api/hooks/useOrder";
import { convertMinutesToTimeFormat } from "@/utils/timeUtil";
import { IconLoader } from "@tabler/icons-react";


const STATUS_OBJ = {
    'dispatched': {
        title: 'On the way to pick-up',
        color: 'text-[#7F56D9]',
        bgColor: 'bg-[#7F56D91A]'
      },
      'pickedup': {
        title: 'On the way to drop-off',
        color: 'text-[#DD2590]',
        bgColor: 'bg-[#DD25901A]'
      },
      "completed" : {
        title: 'Completed',
        color: 'text-[#099a0f]',
        bgColor: 'bg-[#099a0f1A]'
      }
}



const AmbulanceETACard = ({trackingDetail, status}) => {

    let position = trackingDetail?.initialEta ?  `${95 - Math.floor((trackingDetail?.currentEta / trackingDetail?.initialEta)*100)}%` : '-5%';

    if(Math.floor((trackingDetail?.currentEta / trackingDetail?.initialEta)*100) > 100) {
        position = '-5%';
    }

    if(status === 'completed') {
        position = '95%';
    }

    const getCurrentEta = (eta: number) => {
    return convertMinutesToTimeFormat(Math.ceil(eta/60));
    }




    return (
        <Card>
                <div className="p-3 pr-1 flex  justify-between">
                    <div className="flex flex-col w-[28%]">
                        <div className={`text-[#5B6169] text-sm`}>{'Status'}</div>
                        <div className={`${STATUS_OBJ?.[status]?.color} ${STATUS_OBJ?.[status]?.bgColor} text-xs rounded-2xl pl-2 py-0.5`}>{STATUS_OBJ?.[status]?.title}</div>
                    </div>
                    <div className="flex flex-col w-[40%] items-center justify-center">
                    <div className='flex h-px w-full items-center bg-black'>
              <div className='h-2 w-2 bg-black'></div>
              <div className='relative left-[96%] h-2 w-2 rounded bg-black'></div>
              <div className='relative left-[20%] animate-pulse' style={{left: position}}>
                <img src='/images/amb-round.svg' />
              </div>
            </div>
                    </div>
                    <div className="flex flex-col items-center">
                        <div className="text-[#5B6169] text-sm">{status ==='dispatched' ? 'Time to pick-up' : 'Time to drop-off'}</div>
                        <div className="text-[#161B1F] text-lg font-bold">{trackingDetail?.currentEta ? getCurrentEta(trackingDetail?.currentEta) : <IconLoader className='animate-spin' />}</div>
                    </div>
                </div>
            </Card>
    )
}



const _CaseVitalsPage = ({orderId, pusher}: any) => {
    const alertSound = new Audio('assets/audio/Siren.mp3');
    const alertAnimationClasses = 'relative animate-borderColorPulse';
    const { eventsState, removeAlertForOrderId } = useEvents();
    const { ongoingCases} =  useOrders();
    const [alertGlow, setAlertGlow] = useState(false);
    const currentCase = ongoingCases.filter((entry)=> {
        return entry?.caseId === orderId
    })?.[0] ?? {};

    const vitalAlertsForOrderId = eventsState?.[orderId]?.alerts?.filter((entry: any)=> {
        return entry?.eventType === 'VITAL';
    }) ?? [];
    // const vitalAlerts = alertsForOrderId?.filter((entry: any)=> {
    //     return entry?.
    // })

    // if(alertsForOrderId.length === 10 ) {
    //     alertSound.play();
    // }

    // setTimeout(()=> {
    //     removeAlertForOrderId(orderId, alertsForOrderId?.filter((entry:any) => {
    //         return entry?.eventType !== 'VITAL';
    //     }))
    // }, 1000)

    useEffect(()=> {
        if(vitalAlertsForOrderId?.length > 0) {
          setAlertGlow(true);
          removeAlertForOrderId(orderId, eventsState?.[orderId]?.alerts?.filter((entry: any) => {
            return entry?.eventType !== 'VITAL';
          }))
        }
        if(vitalAlertsForOrderId?.length ===0 && alertGlow) {
          setTimeout(()=> {
            setAlertGlow(false);
          }, 5000);
        }
      }, [vitalAlertsForOrderId]);



    
    return (
        <div className="flex px-2 py-2 flex-col" >
            <AmbulanceETACard trackingDetail ={currentCase?.tripTracking} status={currentCase?.overallStatus} />
            <CardWithTitle
                alertClasses={`${alertGlow && alertAnimationClasses}`}
                title={<div className="flex items-center justify-center">
                    <Icon icon='uil:monitor-heart-rate' color='#161B1F'/>
                    <div className="ml-3 text-sm font-semibold"> {"Patient Monitor"} </div>
                    </div>}
                className={'text-center w-full mt-3'}
                styles={{
                    backgroundColor: '#F7F7F7',
                }}
            >
                <div className="h-[40vh] min-h-[300px] w-full">
                    {<PatientMonitoring orderId ={orderId} pusher={pusher} />}
                </div>
            </ CardWithTitle>
            <div>
                <OtherVitalDetails />
            </div>
        </div>
    )
}

const CaseVitalsPage = memo(_CaseVitalsPage);

export default CaseVitalsPage;